class Preload extends Phaser.State {
  preload () {
    this.loadingNumber = this.game.add.text(50, 50, '0 %', {
      font: 'bold 32px Arial',
      fill: '#fff',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    this.game.add.text(this.loadingNumber.x + 65, 50, 'loading...', {
      font: 'bold 32px Arial',
      fill: '#fff',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });

    this.loop = this.game.time.events.loop(10, this.updateLoading, this);

    /* Preload required assets */
    this.game.load.image('I-1', 'assets/I-1.png');
    this.game.load.image('O-1', 'assets/O-1.png');
    this.game.load.image('J-1', 'assets/J-1.png');
    this.game.load.image('L-1', 'assets/L-1.png');
    this.game.load.image('T-1', 'assets/T-1.png');
    this.game.load.image('S-1', 'assets/S-1.png');
    this.game.load.image('Z-1', 'assets/Z-1.png');
    this.game.load.image('I-2', 'assets/I-2.png');
    this.game.load.image('O-2', 'assets/O-2.png');
    this.game.load.image('J-2', 'assets/J-2.png');
    this.game.load.image('L-2', 'assets/L-2.png');
    this.game.load.image('T-2', 'assets/T-2.png');
    this.game.load.image('S-2', 'assets/S-2.png');
    this.game.load.image('Z-2', 'assets/Z-2.png');
    this.game.load.image('I-3', 'assets/I-3.png');
    this.game.load.image('O-3', 'assets/O-3.png');
    this.game.load.image('J-3', 'assets/J-3.png');
    this.game.load.image('L-3', 'assets/L-3.png');
    this.game.load.image('T-3', 'assets/T-3.png');
    this.game.load.image('S-3', 'assets/S-3.png');
    this.game.load.image('Z-3', 'assets/Z-3.png');
    this.game.load.image('btn_rotate', 'assets/rotate.png');
    this.game.load.image('btn_down', 'assets/down.png');
    this.game.load.image('btn_slide', 'assets/slide.png');
    this.game.load.image('logo', 'assets/logo.png');
    this.game.load.image('bg_exit_and_pause', 'assets/bg_exit_and_pause.png');
    this.game.load.image('btn_exit', 'assets/exit_button.png');
    this.game.load.image('btn_pause', 'assets/pause_button.png');
    this.game.load.image('bg_loading', 'assets/bg_loading.png');
    this.game.load.image('load scale', 'assets/loadscale.png');
    this.game.load.image('overload', 'assets/overload.png');
    this.game.load.image('impact', 'assets/impact.png');

    // map
    this.game.load.image('map', 'assets/map/map.png');

    // crane
    this.game.load.image('crane_1', 'assets/crane/crane_one.png');
    this.game.load.image('crane_2', 'assets/crane/crane_two.png');
    this.game.load.image('crane_3', 'assets/crane/crane_three.png');
    this.game.load.image('crane_4', 'assets/crane/crane_four.png');

    // side
    this.game.load.image('bgSideView', 'assets/side/bg.png');
    this.game.load.image('truckSide', 'assets/side/truck.png');
    this.game.load.image('openedRoofSide', 'assets/side/opened_roof.png');

    // side animations
    this.game.load.spritesheet('move', 'assets/animation/side/move.png', 740, 310, 32);
    this.game.load.spritesheet('open', 'assets/animation/side/open.png', 1051, 338, 90);
    this.game.load.spritesheet('close', 'assets/animation/side/close.png', 927, 328, 96);
    this.game.load.spritesheet('slide_left', 'assets/animation/side/slide_left.png', 927, 328, 86);
    this.game.load.spritesheet('slide_right', 'assets/animation/side/slide_right.png', 1051, 338, 82);

    // top
    this.game.load.image('bgTopView', 'assets/top/bg.png');
    this.game.load.image('truckTop', 'assets/top/truck.png');
    this.game.load.image('forklift', 'assets/top/forklift.png');
    this.game.load.image('openedRoofTop', 'assets/top/open.png');

    // animations top
    this.game.load.spritesheet('moveTop', 'assets/animation/top/move.png', 747, 230, 20);
    this.game.load.spritesheet('openTop', 'assets/animation/top/open.png', 762, 323, 131);
    this.game.load.spritesheet('closeTop', 'assets/animation/top/close.png', 747, 325, 131);
    this.game.load.spritesheet('slide_leftTop', 'assets/animation/top/slide_left.png', 829, 325, 66);
    this.game.load.spritesheet('slide_rightTop', 'assets/animation/top/slide_right.png', 828, 325, 66);

    // train
    this.game.load.image('bgTrain', 'assets/train/bg.png');
    this.game.load.image('train', 'assets/train/train.png');
    this.game.load.image('trainOpen', 'assets/train/train_open.png');
    this.game.load.image('man', 'assets/train/man.png');
    this.game.load.image('roofTrain', 'assets/train/roof.png');

    // animations train
    this.game.load.spritesheet('moveTrain', 'assets/animation/train/move.png', 1280, 459, 40);
    this.game.load.spritesheet('openTrain', 'assets/animation/train/open.png', 937, 345, 83);
    this.game.load.spritesheet('closeTrain', 'assets/animation/train/close.png', 941, 348, 92);
    this.game.load.spritesheet('slide_leftTrain', 'assets/animation/train/slide_left.png', 941, 348, 92);
    this.game.load.spritesheet('slide_rightTrain', 'assets/animation/train/slide_right.png', 938, 345, 83);

    // career
    this.game.load.image('bgCareer', 'assets/career/bg.png');
    this.game.load.image('openTruckCareer', 'assets/career/openTruck.png');
    this.game.load.image('closedTruckCareer', 'assets/career/closedTruck.png');
    this.game.load.image('craneCareer', 'assets/career/crane.png');
    this.game.load.image('stone1', 'assets/career/stone1.png');
    this.game.load.image('stone2', 'assets/career/stone2.png');
    this.game.load.image('stone3', 'assets/career/stone3.png');
    this.game.load.image('bgWeightScale', 'assets/career/bg_weight_scale.png');
    this.game.load.image('weightArrow', 'assets/career/arrow.png');
    this.game.load.image('weightKG', 'assets/career/kg.png');

    // animations career
    this.game.load.spritesheet('moveCareer', 'assets/animation/career/move.png', 710, 289);
    this.game.load.spritesheet('openCareer', 'assets/animation/career/open.png', 1037, 330, 134);
    this.game.load.spritesheet('closeCareer', 'assets/animation/career/close.png', 1037, 330, 125);
    this.game.load.spritesheet('loadingCareer', 'assets/animation/career/loading.png', 764, 528);

    // Load our physics data exported from PhysicsEditor
    this.game.load.physics('physicsData', 'assets/sprites.json');

    this.game.load.start();
  }

  updateLoading() {
    if (this.game.load.progress < 100) {
      this.loadingNumber.text = this.game.load.progress + ' %';
    } else {
      this.game.time.events.remove(this.loop);
      this.loadingNumber.destroy();
    }
  }

  create () {
    this.game.state.start('Map');
  }
}

export default Preload;
