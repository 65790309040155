import Truck from '../objects/Career/Truck';
import Crane from '../objects/Career/Crane';

class Career extends Phaser.State {
  create() {
    this.game.view = 'career';
    this.game.careerCurrentLoading = 0;

    const overloadLimits = [220, 240, 260, 280, 300, 340, 380, 400, 450];
    this.game.careerOverloadLimit = overloadLimits[this.game.rnd.integerInRange(0, overloadLimits.length - 1)];
    const firstLoadingStep = Math.floor(this.game.careerOverloadLimit / 3);
    const secondLoadingStep = Math.floor((this.game.careerOverloadLimit / 3) * 2);

    this.onePrecentOverloadLimit = this.game.careerOverloadLimit / 100;

    this.currentLoadingPercent = 0;

    // Enable p2 Physics
    this.game.physics.startSystem(Phaser.Physics.P2JS);
    this.game.physics.p2.gravity.y = 100;

    this.background = this.game.add.image(0, 0, 'bgCareer');
    this.background.width = this.game.width;
    this.background.height = this.game.height;

    this.loadedGoodsText = this.game.add.text(40, 22, 'Loaded goods', {
      font: '25px Arial',
      fill: '#000000'
    });

    this.bg_loading = this.game.add.sprite(30, 65, 'bg_loading');
    this.loadscale = this.game.add.sprite(this.bg_loading.centerX, this.bg_loading.centerY, 'load scale');
    this.loadscale.alignIn(
      this.bg_loading,
      Phaser.CENTER
    );

    this.goodsText = this.game.add.text(this.bg_loading.centerX, this.bg_loading.centerY + 3, this.game.totalMoney.toString() + ' %', {
      font: '30px Arial',
      fill: '#000000'
    });
    this.goodsText.anchor.set(0.5);

    this.cropRectLoadingscale = new Phaser.Rectangle(
      0,
      0,
      0,
      this.loadscale.height
    );
    this.loadscale.crop(this.cropRectLoadingscale);

    this.logo = this.game.add.sprite(this.game.width - 50, 50, 'logo');
    this.logo.anchor.set(1, 0);
    this.logo.scale.setTo(0.6, 0.6);

    this.btnDown = this.game.add.button(this.game.width / 2, this.game.height - 85, 'btn_down', this.craneThrow, this);
    this.btnDown.anchor.set(0.5);
    this.btnDown.scale.setTo(0.7, 0.7);
    this.game.add.text(this.btnDown.x, this.btnDown.y, 'PLACE', {
      font: '40px Arial',
      fill: '#FFFFFF'
    }).anchor.set(0.5);

    this.btnDown.inputEnabled = false;

    this.truck = new Truck(this.game);
    this.game.add.existing(this.truck);
    this.truck.visible = false;

    const truckBoundaries = this.truck.boundaries;

    this.Crane = new Crane(this.game, truckBoundaries);
    this.game.add.existing(this.Crane);

    this.carMove = this.game.add.sprite(-500, this.game.height / 2 + 100, 'moveCareer');
    this.carMove.anchor.set(0.5);
    this.move = this.carMove.animations.add('moveCareer', null, 30, true);
    this.carMove.animations.play('moveCareer');

    this.carTweenStart = this.game.add.tween(this.carMove).to({ x: this.game.width / 2 }, 2000, Phaser.Easing.Quadratic.InOut, true, 0, 0, false);

    this.carTweenStart.onComplete.add(() => {
      this.move.stop(null, true);
    });

    this.carTweenEnd = this.game.add.tween(this.carMove).to({ x: this.game.width + 1000 }, 2000, Phaser.Easing.Quadratic.InOut, false, 0, 0, false);

    this.carTweenEnd.onComplete.add(() => {
      this.game.totalMoney = 0;
      this.game.levels = this.game.levels.filter(level => level !== 'Career');
      this.game.state.start('Map');
    });

    this.carOpen = this.game.add.sprite(this.game.width / 2, this.game.height / 2 + 65, 'openCareer');
    this.carOpen.anchor.set(0.5);
    this.open = this.carOpen.animations.add('openCareer', null, 40, false);
    this.carOpen.visible = false;

    this.carClosed = this.game.add.sprite(this.game.width / 2, this.game.height / 2 + 65, 'closeCareer');
    this.carClosed.anchor.set(0.5);
    this.close = this.carClosed.animations.add('closeCareer', null, 30, false);
    this.carClosed.visible = false;

    this.move.onComplete.add(() => {
      this.carMove.visible = false;
      this.carOpen.visible = true;
      this.carOpen.animations.play('openCareer');
    }, this);

    this.open.onComplete.add(() => {
      this.btnDown.inputEnabled = true;

      this.truck.visible = true;
      this.carClosed.visible = true;
      this.carOpen.visible = false;

      this.Crane.stop = false;
      this.Crane.isDoNewStone = true;
    }, this);

    this.close.onComplete.add(() => {
      this.Crane.stonesGroup.killAll();
      this.carClosed.visible = false;
      this.truck.visible = false;
      this.carMove.visible = true;
      this.carMove.animations.play('moveCareer');
      this.carTweenEnd.start();
    });

    this.bg_buttons = this.game.add.image(this.game.width, this.game.height / 2, 'bg_exit_and_pause');
    this.bg_buttons.anchor.set(1, 0.5);
    this.game.add.button(this.bg_buttons.x - this.bg_buttons.width / 2, this.bg_buttons.y - this.bg_buttons.height / 2 + 10, 'btn_exit', this.exit, this).anchor.set(0.5, 0);
    this.game.add.button(this.bg_buttons.x - this.bg_buttons.width / 2, this.bg_buttons.y + this.bg_buttons.height / 2 - 10, 'btn_pause', this.pause, this).anchor.set(0.5, 1);

    this.bgWeightScale = this.game.add.image(180, this.game.height - 200, 'bgWeightScale');
    this.bgWeightScale.anchor.x = 0.5;
    this.weightArrow = this.game.add.image(
      this.bgWeightScale.x,
      this.bgWeightScale.y + this.bgWeightScale.height - 15,
      'weightArrow',
    );
    this.weightArrow.anchor.set(1, 0.5);

    this.weightArrow.angle = 90;

    this.kg = this.game.add.image(
      this.bgWeightScale.x,
      this.bgWeightScale.bottom,
      'weightKG',
    );
    this.kg.anchor.set(0.5, 1);

    this.nullStepText = this.game.add.text(
      this.bgWeightScale.left - 20,
      this.bgWeightScale.bottom - 25,
      '0',
      {
        fill: '#FFFFFF',
        fontSize: 25,
      }
    );
    this.firstStepText = this.game.add.text(
      this.bgWeightScale.left + 45,
      this.bgWeightScale.bottom - 128,
      '' + firstLoadingStep,
      {
        fill: '#FFFFFF',
        fontSize: 25,
      }
    );
    this.firstStepText.anchor.x = 1;
    this.secondStepText = this.game.add.text(
      this.bgWeightScale.x,
      this.bgWeightScale.top - 30,
      '' + secondLoadingStep,
      {
        fill: '#FFFFFF',
        fontSize: 25,
      }
    );
    this.secondStepText.anchor.x = 0.5;
    this.finalStepText = this.game.add.text(
      this.bgWeightScale.right - 45,
      this.bgWeightScale.bottom - 130,
      '' + this.game.careerOverloadLimit,
      {
        fill: '#FFFFFF',
        fontSize: 25,
      }
    );
  }

  update() {
    this.goodsText.text = this.game.totalMoney + ' %';
    this.goodsText.x = this.bg_loading.centerX;

    this.cropRectLoadingscale.width = 239 / 100 * this.game.totalMoney;
    this.loadscale.updateCrop();

    this.currentLoadingPercent = Math.floor(this.game.careerCurrentLoading / this.onePrecentOverloadLimit);

    this.weightArrow.angle = 1.35 * this.currentLoadingPercent;

    if (this.game.isGameOver) {
      this.game.physics.clear();
      this.game.add.image(this.game.width / 2, this.game.height / 2 - 100, 'overload').anchor.set(0.5);
    }

    if (this.game.levelDone === true) {
      this.game.levelDone = false;
      this.carClosed.visible = true;
      this.carClosed.animations.play('closeCareer');
    }
  }

  craneThrow() {
    this.Crane.throwStone();
  }

  exit() {
    this.game.state.start('Map');
  }

  pause() {
    this.game.paused = (this.game.paused) ? false : true;

    if (this.game.paused) {
      this.btnDown.inputEnabled = false;
    } else {
      this.btnDown.inputEnabled = true;
    }
  }
}

export default Career;
