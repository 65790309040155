class Tetromino extends Phaser.Sprite {
  constructor(game, crane, truck) {
    super(game, crane.x, crane.y, 'I-1')
    this.anchor.set(0.5)

    this.game = game
    this.crane = crane
    this.truck = truck

    this.truckBoundaries = this.truck.boundaries
    this.isThrowed = false
    this.isFall = false
    this.isRotated = false
    this.isHit = false
    this.money = 10
    this.speed = 300

    this.game.physics.p2.enable(this)

    const types = ['I', 'O', 'T', 'J', 'L', 'S', 'Z']
    let texture = this.game.rnd.between(1, 3)
    let _type = this.game.rnd.between(0, 6)

    this.type = types[_type]

    this.crane.changeCrane(this.type, this.body.angle, this.x)

    this.loadTexture(this.type + '-' + texture)

    this.body.clearShapes()
    this.body.loadPolygon('physicsData', this.type)

    this.body.mass = 15

    this.body.static = true

    this.body.onBeginContact.addOnce(this.hit, this)
  }

  hit(body, bodyB, shapeA, shapeB, equation) {
    this.isThrowed = false;
    this.isFall = true;

    if (body.sprite.key === 'openedRoofSide') {
      // this.game.isPlaced = false;
      this.game.typeGameOver = 'impact';
      this.game.isGameOver = true;
    } else {
      this.isHit = true;
      this.crane.groupTetromino.add(this);
      this.crane.tetrominoCreated = false;

      if (this.crane.groupTetromino.countLiving() > 0 && Math.floor(this.crane.groupTetromino.getBounds().y) < this.truckBoundaries.top) {
        this.crane.stop = true;
        this.game.typeGameOver = 'overload';
        this.game.isGameOver = true;
      } else {
        if (Math.floor(this.crane.groupTetromino.getBounds().y) > this.truckBoundaries.top) {
          this.game.totalMoney += this.money;
        }
        if (this.game.moneyLimit === this.game.totalMoney) {
          this.crane.stop = true;
          this.game.levelDone = true;
        }
      }
    }
  }

  moveDawn() {
    this.body.velocity.x = 0;
    this.body.static = false;
    this.isThrowed = true;
  }

  move() {
    if (!this.isThrowed && !this.isFall) {
      let tetrominoWidth

      if (this.body.angle === 90 || this.body.angle === -90) {
        tetrominoWidth = this.height
      } else {
        tetrominoWidth = this.width
      }

      if (this.body.x - tetrominoWidth / 2 <= this.truckBoundaries.left) {
        this.crane.direction = 'right'
      } else if (this.body.x + tetrominoWidth / 2 >= this.truckBoundaries.right) {
        this.crane.direction = 'left'
      }

      if (this.crane.direction === 'left') {
        this.body.moveLeft(this.speed)
      } else {
        this.body.moveRight(this.speed)
      }

      if (this.type === 'J' && this.body.angle === 90) {
        this.crane.x = this.x + 20;
      } else if (this.type === 'L' && this.body.angle === -90) {
        this.crane.x = this.x - 20;
      } else if (this.type === 'T' && this.body.angle === 90) {
        this.crane.x = this.x - 20;
      } else if (this.type === 'T' && this.body.angle === -90) {
        this.crane.x = this.x + 20;
      } else if (this.type === 'Z' && (this.body.angle === 0 || this.body.angle === -180)) {
        this.crane.x = this.x - 20;
      } else if (this.type === 'Z' && (this.body.angle === 90 || this.body.angle === -90)) {
        this.crane.x = this.x + 15;
      } else if (this.type === 'S' && (this.body.angle === 0 || this.body.angle === -180)) {
        this.crane.x = this.x + 20;
      } else if (this.type === 'S' && (this.body.angle === 90 || this.body.angle === -90)) {
        this.crane.x = this.x - 15;
      } else {
        this.crane.x = this.x;
      }

      if (this.crane.direction === 'left') {
        this.crane.x -= 2
      } else {
        this.crane.x += 2
      }
    }
  }

  rotate() {
    if (!this.isThrowed) {
      this.body.angle += 90

      this.crane.changeCrane(this.type, this.body.angle, this.x)

      if ((this.body.angle === 90 || this.body.angle === -90) && this.type !== 'O') {
        if (this.type === 'I') {
          this.body.y = this.crane.y - 20;
        } else {
          this.body.y = this.crane.y + 20;
        }
      } else if (this.body.angle === -180 && (this.type === 'L' || this.type === 'J')) {
        this.body.y = this.crane.y - 40;
      } else {
        this.body.y = this.crane.y;
      }

      if (this.body.angle === 360) {
        this.body.angle = 0
      }
    }
  }

  update() {
    if (this.crane.stop === false) {
      this.move()
    }

    if (this.isThrowed && !this.isHit) {
      if ((this.body.angle === 90 || this.body.angle === -90) && this.type !== 'O') {
        if (this.type === 'I') {
          this.crane.y = this.y + 20;
        } else {
          this.crane.y = this.y - 20;
        }
      } else if (this.body.angle === -180 && (this.type === 'L' || this.type === 'J')) {
        this.crane.y = this.y + 40;
      } else {
        this.crane.y = this.y
      }
    }

    if (this.isHit) {
      if (this.crane.y - 151 > 0) {
        this.crane.y -= 2;
      }
      if (this.crane.y - 151 <= 0) {
        this.isHit = false;
        this.game.isPlaced = false;
        if (!this.game.isGameOver && !this.game.levelDone) {
          this.crane.generateTetromino(this.game, this.crane, this.truck);
        }
      }
    }
  }
}

export default Tetromino
