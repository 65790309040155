import Boot from './states/Boot';
import Preload from './states/Preload';
import startMap from './states/Map';
import TopView from './states/TopView';
import SideView from './states/SideView';
import Train from './states/Train';
import Career from './states/Career';

class Game extends Phaser.Game {
  constructor () {
    var width = 1280;
    var height = 720;
    var windowRatio = window.innerWidth / window.innerHeight;

    if (windowRatio < width / height) {
      height = width / windowRatio;
    }

    super(width, height, Phaser.CANVAS);

    this.localStorageName = 'trucktopview';
    this.savedData = localStorage.getItem(this.localStorageName) == null ? { highScore: 0 } : JSON.parse(localStorage.getItem(this.localStorageName));

    this.levels = [
      'SideView',
      'TopView',
      'Train',
      'Career',
    ];

    this.totalMoney = 0;
    this.moneyLimit = 100;
    this.careerCurrentLoading = 0;
    this.careerOverloadLimit = 0;
    this.levelDone = false;
    this.isGameOver = false;
    this.typeGameOver;
    this.view = '';
    this.isPlaced = false;

    this.state.add('Boot', Boot, false);
    this.state.add('Preload', Preload, false);
    this.state.add('Map', startMap, false);
    this.state.add('TopView', TopView, false);
    this.state.add('SideView', SideView, false);
    this.state.add('Train', Train, false);
    this.state.add('Career', Career, false);

    this.state.start('Boot');
  }
}

const game = new Game();
