import Truck from '../objects/SideView/Truck';
import Crane from '../objects/SideView/Crane';

class Main extends Phaser.State {
  create () {
    this.game.view = 'side'

    // Enable p2 Physics
    this.game.physics.startSystem(Phaser.Physics.P2JS);
    this.game.physics.p2.gravity.y = 100;

    this.background = this.game.add.image(0, 0, 'bgSideView');
    this.background.width = this.game.width;
    this.background.height = this.game.height;

    this.loadedGoodsText = this.game.add.text(40, 30, 'Loaded goods', {
      font: '25px Arial',
      fill: '#000000'
    });

    this.bg_loading = this.game.add.sprite(20, 74, 'bg_loading');
    this.loadscale = this.game.add.sprite(this.bg_loading.centerX, this.bg_loading.centerY, 'load scale');
    this.loadscale.alignIn(
      this.bg_loading,
      Phaser.CENTER
    );

    this.cropRectLoadingscale = new Phaser.Rectangle(
      0,
      0,
      0,
      this.loadscale.height
    );
    this.loadscale.crop(this.cropRectLoadingscale);

    this.goodsText = this.game.add.text(this.bg_loading.centerX, this.bg_loading.centerY + 3, this.game.totalMoney.toString() + ' %', {
      font: '30px Arial',
      fill: '#000000'
    });
    this.goodsText.anchor.set(0.5);

    this.logo = this.game.add.sprite(this.game.width - 50, 50, 'logo')
    this.logo.anchor.set(1, 0)
    this.logo.scale.setTo(0.6, 0.6)

    this.btnDown = this.game.add.button(this.game.width / 2, this.game.height - 85, 'btn_down', this.craneThrow, this)
    this.btnDown.anchor.set(0.5)
    this.btnDown.scale.setTo(0.7, 0.7)
    this.game.add.text(this.btnDown.x, this.btnDown.y, 'PLACE', {
      font: '40px Arial',
      fill: '#FFFFFF'
    }).anchor.set(0.5)

    this.btnDown.inputEnabled = false

    this.btnMoveRoof = this.game.add.button(this.game.width - 250, this.game.height - 85, 'btn_slide', this.moveRoof, this)
    this.btnMoveRoof.anchor.set(0.5)
    this.btnMoveRoof.scale.setTo(0.7, 0.7)
    this.game.add.text(this.btnMoveRoof.x, this.btnMoveRoof.y, 'SLIDE', {
      font: '40px Arial',
      fill: '#FFFFFF'
    }).anchor.set(0.5)

    this.btnMoveRoof.inputEnabled = false

    this.btnRotate = this.game.add.button(250, this.game.height - 85, 'btn_rotate', this.craneRotate, this)
    this.btnRotate.anchor.set(0.5)
    this.btnRotate.scale.setTo(0.7, 0.7)
    this.game.add.text(this.btnRotate.x, this.btnRotate.y, 'ROTATE', {
      font: '40px Arial',
      fill: '#FFFFFF'
    }).anchor.set(0.5)

    this.btnRotate.inputEnabled = false

    this.truckMove = this.game.add.sprite(-1000, this.game.height / 2 + 80, 'move')
    this.truckMove.anchor.set(0.5)
    this.move = this.truckMove.animations.add('move', null, 40, true)
    this.truckMove.animations.play('move')

    this.truckOpen = this.game.add.sprite(this.game.width / 2 + 10, this.game.height / 2 + 55, 'open')
    this.truckOpen.anchor.set(0.5)
    this.open = this.truckOpen.animations.add('open', null, 30)
    this.truckOpen.visible = false

    this.truckTweenStart = this.game.add.tween(this.truckMove).to({ x: this.game.width / 2 }, 2000, Phaser.Easing.Quadratic.InOut, true, 0, 0, false)

    this.truckTweenEnd = this.game.add.tween(this.truckMove).to({ x: this.game.width + 1000 }, 2000, Phaser.Easing.Quadratic.InOut, false, 0, 0, false)

    this.truckTweenStart.onComplete.add(() => {
      this.move.stop(null, true)
    })

    this.truckTweenEnd.onComplete.add(() => {
      this.game.levels = this.game.levels.filter(level => level !== 'SideView');
      this.game.state.start('Map');
    });

    this.Truck = new Truck(this.game, 'Side')
    this.game.add.existing(this.Truck)
    this.Truck.visible = false

    this.openedRoof = this.game.add.sprite(
      this.Truck.x - this.Truck.width / 2 + 530,
      this.Truck.y - this.Truck.height / 2 - 10,
      'openedRoofSide')
    this.openedRoof.anchor.y = 1
    this.openedRoof.visible = false

    this.openedRoofIsRight = true

    this.game.physics.p2.enable(this.openedRoof);

    this.openedRoof.body.clearShapes();
    this.openedRoof.body.loadPolygon('physicsData', 'sideTruckRoof');

    this.openedRoof.body.static = true;

    this.Crane = new Crane(this.game, 'Side')
    this.game.add.existing(this.Crane)

    this.Crane.generateTetromino(this.game, this.Crane, this.Truck)

    this.slideLeft = this.game.add.sprite(this.game.width / 2 - 5, this.game.height / 2 + 50, 'slide_left')
    this.slideLeft.anchor.set(0.5)
    this.roof_slideLeft = this.slideLeft.animations.add('slide_left', null, 30)
    this.slideLeft.visible = false

    this.slideRight = this.game.add.sprite(this.game.width / 2 + 15, this.game.height / 2 + 55, 'slide_right')
    this.slideRight.anchor.set(0.5)
    this.roof_slideRight = this.slideRight.animations.add('slide_right', null, 30)
    this.slideRight.visible = false

    this.truckClose = this.game.add.sprite(this.game.width / 2 - 5, this.game.height / 2 + 53, 'close')
    this.truckClose.anchor.set(0.5)
    this.close = this.truckClose.animations.add('close', null, 30)
    this.truckClose.visible = false

    this.move.onComplete.add(() => {
      this.truckMove.visible = false
      this.truckOpen.visible = true
      this.truckOpen.animations.play('open')
    }, this)

    this.open.onComplete.add(() => {
      this.btnDown.inputEnabled = true
      this.btnMoveRoof.inputEnabled = true
      this.btnRotate.inputEnabled = true

      this.truckOpen.visible = false
      this.Truck.visible = true
      this.slideLeft.visible = true

      this.Crane.stop = false
    }, this)

    this.roof_slideLeft.onStart.add(() => {
      this.btnDown.inputEnabled = false
      this.btnMoveRoof.inputEnabled = false
      this.btnRotate.inputEnabled = false
    }, this)

    this.roof_slideLeft.onComplete.add(() => {
      this.btnDown.inputEnabled = true
      this.btnMoveRoof.inputEnabled = true
      this.btnRotate.inputEnabled = true

      this.slideLeft.visible = false
      this.slideRight.visible = true
      this.slideLeft.frame = 0

      this.openedRoofIsRight = !this.openedRoofIsRight
    }, this)

    this.roof_slideRight.onStart.add(() => {
      this.btnDown.inputEnabled = false
      this.btnMoveRoof.inputEnabled = false
      this.btnRotate.inputEnabled = false
    }, this)

    this.roof_slideRight.onComplete.add(() => {
      this.btnDown.inputEnabled = true
      this.btnMoveRoof.inputEnabled = true
      this.btnRotate.inputEnabled = true

      if (this.game.levelDone) {
        this.slideRight.visible = false
        this.slideRight.frame = 0

        this.truckClose.visible = true
        this.truckClose.animations.play('close')
      } else {
        this.slideRight.visible = false
        this.slideLeft.visible = true
        this.slideRight.frame = 0
      }

      this.openedRoofIsRight = !this.openedRoofIsRight
    }, this)

    this.close.onStart.add(() => {
      this.btnDown.inputEnabled = false
      this.btnMoveRoof.inputEnabled = false
      this.btnRotate.inputEnabled = false

      this.slideRight.visible = false
      this.slideLeft.visible = false
    })

    this.close.onComplete.add(() => {
      this.Crane.groupTetromino.killAll();
      this.game.levelDone = false;

      this.truckClose.visible = false;
      this.close.frame = 0;
      this.Truck.visible = false;
      this.truckMove.visible = true;
      this.truckMove.animations.play('move');
      this.truckTweenEnd.start();
    })

    this.bg_buttons = this.game.add.image(this.game.width, this.game.height / 2, 'bg_exit_and_pause')
    this.bg_buttons.anchor.set(1, 0.5)
    this.game.add.button(this.bg_buttons.x - this.bg_buttons.width / 2, this.bg_buttons.y - this.bg_buttons.height / 2 + 10, 'btn_exit', this.exit, this).anchor.set(0.5, 0)
    this.game.add.button(this.bg_buttons.x - this.bg_buttons.width / 2, this.bg_buttons.y + this.bg_buttons.height / 2 - 10, 'btn_pause', this.pause, this).anchor.set(0.5, 1)
  }

  update () {
    this.goodsText.text = this.game.totalMoney + ' %';
    this.goodsText.x = this.bg_loading.centerX;

    this.cropRectLoadingscale.width = 239 / 100 * this.game.totalMoney;
    this.loadscale.updateCrop();

    if (this.game.isGameOver) {
      this.btnDown.inputEnabled = false;
      this.btnMoveRoof.inputEnabled = false;
      this.btnRotate.inputEnabled = false;
      this.game.physics.clear();
      this.game.add.image(this.game.width / 2, this.game.height / 2 - 100, this.game.typeGameOver).anchor.set(0.5);
    }

    if (this.game.levelDone) {
      if (!this.openedRoofIsRight) {
        this.openedRoof.body.x = this.Truck.x - this.Truck.width / 2 + 530
        this.slideRight.animations.play('slide_right')
      } else {
        this.truckClose.visible = true
        this.truckClose.animations.play('close')
      }
    }
  }

  craneThrow () {
    if (!this.game.isPlaced) {
      this.Crane.throwTetromino();
      this.game.isPlaced = true;
    }
  }

  craneRotate () {
    if (!this.game.isPlaced) {
      this.Crane.rotateTetromino();
    }
  }

  moveRoof () {
    if (!this.game.isPlaced) {
      if (this.openedRoofIsRight) {
        this.openedRoof.body.x = this.Truck.x - this.Truck.width / 2 + 55;
        this.slideLeft.animations.play('slide_left');
      } else {
        this.openedRoof.body.x = this.Truck.x - this.Truck.width / 2 + 530;
        this.slideRight.animations.play('slide_right');
      }
    }
  }

  exit () {
    this.game.state.start('Map');
  }

  pause () {
    this.game.paused = (this.game.paused) ? false : true
  }
}

export default Main
