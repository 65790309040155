class Truck extends Phaser.Sprite {
  constructor(game) {
    super(game, game.width / 2 - 3, game.height / 2 + 83, 'openTruckCareer');
    this.anchor.set(0.5);

    this.game = game;

    this.game.physics.p2.enable(this);

    this.body.clearShapes();

    this.body.loadPolygon('physicsData', 'careerTruck');

    this.body.static = true;
  }

  get boundaries() {
    let boundaries = {
      left: this.left + 58,
      right: this.left + 479,
      top: this.top + 46,
    };

    return boundaries;
  }
}

export default Truck;
