import Stone from './Stone';

class Crane extends Phaser.Sprite {
  constructor(game, boundaries) {
    super(game, game.width / 2, 0, 'craneCareer');
    this.anchor.set(0.5, 0);
    this.game = game;

    this.boundaries = boundaries;
    this.direction = 'left';
    this.stop = true;
    this.isDoNewStone = false;
    this.stonesGroup = this.game.add.group();

    this.speed = 240;

    this.game.physics.enable(this, Phaser.Physics.ARCADE);
  }

  move() {
    if (this.direction === 'left') {
      this.body.velocity.x = -this.speed;
    } else {
      this.body.velocity.x = this.speed;
    }
  }

  throwStone() {
    if (this.isDoNewStone) {
      const stone = new Stone(this.game, this, this.boundaries.top);
      this.game.add.existing(stone);
      this.isDoNewStone = false;
    }
  }

  update() {
    if (this.left <= this.boundaries.left) {
      this.direction = 'right';
    }
    if (this.right >= this.boundaries.right) {
      this.direction = 'left';
    }
    if (this.stop === false) {
      this.move();
    }
  }
}

export default Crane;
