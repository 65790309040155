class Stone extends Phaser.Sprite {
  constructor(game, crane, truckBoundariesTop) {
    super(game, crane.x, crane.bottom, 'stone1');
    this.anchor.set(0.5);
    this.scale.setTo(1.1);

    this.game = game;
    this.crane = crane;
    this.truckBoundariesTop = truckBoundariesTop;
    this.money = 4;
    this.weight = 0;

    const name = 'stone' + this.game.rnd.integerInRange(1, 3);

    this.loadTexture(name);

    this.game.physics.p2.enable(this);

    if (name === 'stone1') {
      this.weight = this.game.rnd.integerInRange(10, 15);
      this.body.clearShapes();
      this.body.setCircle(15);
    } else if (name === 'stone2') {
      this.weight = this.game.rnd.integerInRange(8, 13);
      this.body.mass = 15;
    } else if (name === 'stone3') {
      this.weight = this.game.rnd.integerInRange(5, 10);
      this.body.clearShapes();
      this.body.setCircle(10);
      this.body.mass = 5;
    }

    this.body.onBeginContact.addOnce(this.hit, this);
  }

  hit(bodyA, bodyB, shapeA, shapeB, equation) {
    this.crane.stonesGroup.add(this);
    this.game.totalMoney += this.money;

    this.game.careerCurrentLoading += this.weight;

    if (this.game.careerCurrentLoading > this.game.careerOverloadLimit + 15) {
      this.game.isGameOver = true;
    } else if (this.game.totalMoney === this.game.moneyLimit) {
      this.crane.stop = true;
      this.crane.body.velocity.x = 0;
      this.game.levelDone = true;
    } else {
      this.crane.isDoNewStone = true;
    }
  }
}

export default Stone;
