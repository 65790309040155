class Truck extends Phaser.Sprite {
  constructor (game, gameLevel) {
    super(game, game.width / 2 - 5, game.height / 2 + 64, 'truckSide');
    this.anchor.set(0.5);

    this.game = game;
    this.gameLevel = gameLevel;

    this.game.physics.p2.enable(this);

    this.body.clearShapes();

    this.body.loadPolygon('physicsData', 'truck');

    this.body.static = true;
  }

  get boundaries () {
    let boundaries = {
      left: this.x - this.width / 2 + 20,
      right: this.x - this.width / 2 + 575,
      top: this.top,
    };

    return boundaries;
  }
}

export default Truck;
