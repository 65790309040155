class startMap extends Phaser.State {
  create() {
    this.game.totalMoney = 0;
    this.game.isGameOver = false;
    this.game.isPlaced = false;
    this.numLevel = 0;

    this.bg = this.game.add.image(0, 0, 'map');
    this.bg.width = this.game.width;
    this.bg.height = this.game.height;

    this.bgState = this.game.add.image(0, 0, 'bgTrain');
    this.bgState.scale.setTo(0.1);
    this.bgState.visible = false;

    this.tween = this.game.add.tween(this.bgState.scale).to({ x: 1, y: 1 }, 1000, 'Linear', false, 0);

    this.tween.onComplete.add(() => {
      this.state.start(this.game.levels[this.numLevel]);
    });

    this.startBtn = this.game.add.text(
      this.game.width / 2,
      this.game.height / 2 + 140,
      'START',
      {
        font: '25px Arial',
        fill: '#000000',
      },
    );

    this.startBtn.inputEnabled = true;

    this.startBtn.events.onInputDown.add(this.start, this);

    this.rect = new Phaser.Rectangle(0, 0, 450, 280);

    const rectCoord = {
      'SideView': {x: this.game.width - 450, y: this.game.height - 280},
      'TopView': {x: 0, y: this.game.height - 280},
      'Train': {x: 0, y: 0},
      'Career': {x: this.game.width - 450, y: 0},
    };

    this.bgStateCoord = {
      'SideView': { x: this.game.width, y: this.game.height, pointX: 1, pointY: 1 },
      'TopView': { x: 0, y: this.game.height, pointX: 0, pointY: 1 },
      'Train': { x: 0, y: 0, pointX: 0, pointY: 0 },
      'Career': { x: this.game.width, y: 0, pointX: 1, pointY: 0 },
    };

    this.timer = setInterval(() => {
      this.numLevel = this.game.rnd.integerInRange(0, this.game.levels.length - 1);

      this.rect.x = rectCoord[this.game.levels[this.numLevel]].x;
      this.rect.y = rectCoord[this.game.levels[this.numLevel]].y;
    }, 300);
  }

  start() {
    clearInterval(this.timer);
    this.bgState.loadTexture('bg' + this.game.levels[this.numLevel]);
    this.bgState.anchor.set(
      this.bgStateCoord[this.game.levels[this.numLevel]].pointX,
      this.bgStateCoord[this.game.levels[this.numLevel]].pointY
    );
    this.bgState.x = this.bgStateCoord[this.game.levels[this.numLevel]].x;
    this.bgState.y = this.bgStateCoord[this.game.levels[this.numLevel]].y;
    this.bgState.visible = true;
    this.startBtn.visible = false;

    this.tween.start();
  }

  render() {
    this.game.debug.geom(this.rect, '#00bff3', false);
  }
}

export default startMap;
