class Truck extends Phaser.Sprite {
  constructor (game) {
    super(game, game.width / 2 + 6, game.height / 2 + 36, 'trainOpen')
    this.anchor.set(0.5);

    this.game = game;

    this.game.physics.p2.enable(this);

    this.body.clearShapes();

    this.body.loadPolygon('physicsData', 'train');

    this.body.static = true;
  }

  get boundaries () {
    return {
      left: this.x - this.width / 2 + 46,
      right: this.x - this.width / 2 + 809,
      top: this.y - this.height / 2 + 8,
    };
  }
}

export default Truck;
