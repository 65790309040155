import Truck from '../objects/Train/Truck';
import Crane from '../objects/Train/Crane';

class Train extends Phaser.State {
  create () {
    this.levelDone = false;

    // Enable p2 Physics
    this.game.physics.startSystem(Phaser.Physics.P2JS)
    this.game.physics.p2.gravity.y = 100

    this.background = this.game.add.image(0, 0, 'bgTrain')
    this.background.width = this.game.width
    this.background.height = this.game.height

    this.loadedGoodsText = this.game.add.text(30, 30, 'Loaded goods', {
      font: '25px Arial',
      fill: '#000000'
    });

    this.bg_loading = this.game.add.sprite(20, 74, 'bg_loading');
    this.loadscale = this.game.add.sprite(this.bg_loading.centerX, this.bg_loading.centerY, 'load scale');
    this.loadscale.alignIn(
      this.bg_loading,
      Phaser.CENTER
    );

    this.cropRectLoadingscale = new Phaser.Rectangle(
      0,
      0,
      0,
      this.loadscale.height
    );
    this.loadscale.crop(this.cropRectLoadingscale);

    this.goodsText = this.game.add.text(this.bg_loading.centerX, this.bg_loading.centerY + 3, this.game.totalMoney.toString() + ' %', {
      font: '30px Arial',
      fill: '#000000'
    });
    this.goodsText.anchor.set(0.5);

    this.logo = this.game.add.sprite(this.game.width - 50, 50, 'logo')
    this.logo.anchor.set(1, 0)
    this.logo.scale.setTo(0.6, 0.6)

    this.btnDown = this.game.add.button(this.game.width / 2, this.game.height - 85, 'btn_down', this.craneThrow, this)
    this.btnDown.anchor.set(0.5)
    this.btnDown.scale.setTo(0.7, 0.7)
    this.game.add.text(this.btnDown.x, this.btnDown.y, 'PLACE', {
      font: '40px Arial',
      fill: '#FFFFFF'
    }).anchor.set(0.5)

    this.btnDown.inputEnabled = false

    this.btnMoveRoof = this.game.add.button(this.game.width - 250, this.game.height - 85, 'btn_slide', this.moveRoof, this)
    this.btnMoveRoof.anchor.set(0.5)
    this.btnMoveRoof.scale.setTo(0.7, 0.7)
    this.game.add.text(this.btnMoveRoof.x, this.btnMoveRoof.y, 'SLIDE', {
      font: '40px Arial',
      fill: '#FFFFFF'
    }).anchor.set(0.5)

    this.btnMoveRoof.inputEnabled = false

    this.btnRotate = this.game.add.button(250, this.game.height - 85, 'btn_rotate', this.craneRotate, this)
    this.btnRotate.anchor.set(0.5)
    this.btnRotate.scale.setTo(0.7, 0.7)
    this.game.add.text(this.btnRotate.x, this.btnRotate.y, 'ROTATE', {
      font: '40px Arial',
      fill: '#FFFFFF'
    }).anchor.set(0.5)

    this.btnRotate.inputEnabled = false

    this.trainMove = this.game.add.sprite(this.game.width / 2, this.game.height / 2 + 5, 'moveTrain')
    this.trainMove.anchor.set(0.5)
    this.move = this.trainMove.animations.add('moveTrain', null, 30, false)
    this.trainMove.animations.play('moveTrain')

    this.trainLeft = this.game.add.sprite(this.game.width / 2 - 415, this.game.height / 2 + 43, 'train')
    this.trainLeft.anchor.set(1, 0.5)
    this.trainLeft.visible = false
    this.trainRight = this.game.add.sprite(this.game.width / 2 + 430, this.game.height / 2 + 43, 'train')
    this.trainRight.anchor.set(0, 0.5)
    this.trainRight.visible = false

    this.trainOpen = this.game.add.sprite(this.game.width / 2 - 30, this.game.height / 2 + 55, 'openTrain')
    this.trainOpen.anchor.set(0.5)
    this.open = this.trainOpen.animations.add('openTrain', null, 20)
    this.trainOpen.visible = false

    this.Truck = new Truck(this.game)
    this.game.add.existing(this.Truck)
    this.Truck.visible = false

    this.openedRoofTrain = this.game.add.sprite(
      this.Truck.x + this.Truck.width / 2 - 130,
      this.Truck.y - this.Truck.height / 2 + 110,
      'roofTrain')
    this.openedRoofTrain.visible = false

    this.openedRoofIsRight = true

    this.game.physics.p2.enable(this.openedRoofTrain);

    this.openedRoofTrain.body.clearShapes();
    this.openedRoofTrain.body.loadPolygon('physicsData', 'trainRoof');

    this.openedRoofTrain.body.static = true;

    this.Crane = new Crane(this.game)
    this.game.add.existing(this.Crane)

    this.Crane.generateTetromino(this.game, this.Crane, this.Truck)

    this.slideLeft = this.game.add.sprite(this.game.width / 2 - 30, this.game.height / 2 + 57, 'slide_leftTrain')
    this.slideLeft.anchor.set(0.5)
    this.roof_slideLeft = this.slideLeft.animations.add('slide_leftTrain', null, 20)
    this.slideLeft.visible = false

    this.slideRight = this.game.add.sprite(this.game.width / 2 - 30, this.game.height / 2 + 55, 'slide_rightTrain')
    this.slideRight.anchor.set(0.5)
    this.roof_slideRight = this.slideRight.animations.add('slide_rightTrain', null, 20)
    this.slideRight.visible = false

    this.trainClose = this.game.add.sprite(this.game.width / 2 - 30, this.game.height / 2 + 55, 'closeTrain')
    this.trainClose.anchor.set(0.5)
    this.close = this.trainClose.animations.add('closeTrain', null, 20)
    this.trainClose.visible = false

    this.move.onComplete.add(() => {
      if (this.levelDone) {
        this.game.levels = this.game.levels.filter(level => level !== 'Train');
        this.game.state.start('Map');
      } else {
        this.trainMove.visible = false
        this.trainLeft.visible = true
        this.trainRight.visible = true
        this.trainOpen.visible = true
        this.trainOpen.animations.play('openTrain')
      }
    }, this)

    this.open.onComplete.add(() => {
      this.btnDown.inputEnabled = true
      this.btnMoveRoof.inputEnabled = true
      this.btnRotate.inputEnabled = true

      this.trainOpen.visible = false
      this.Truck.visible = true
      this.slideLeft.visible = true

      this.Crane.stop = false
    }, this)

    this.roof_slideLeft.onStart.add(() => {
      this.btnDown.inputEnabled = false
      this.btnMoveRoof.inputEnabled = false
      this.btnRotate.inputEnabled = false
    }, this)

    this.roof_slideLeft.onComplete.add(() => {
      this.btnDown.inputEnabled = true
      this.btnMoveRoof.inputEnabled = true
      this.btnRotate.inputEnabled = true

      this.slideLeft.visible = false
      this.slideRight.visible = true
      this.slideLeft.frame = 0

      this.openedRoofIsRight = !this.openedRoofIsRight
    }, this)

    this.roof_slideRight.onStart.add(() => {
      this.btnDown.inputEnabled = false
      this.btnMoveRoof.inputEnabled = false
      this.btnRotate.inputEnabled = false
    }, this)

    this.roof_slideRight.onComplete.add(() => {
      this.btnDown.inputEnabled = true
      this.btnMoveRoof.inputEnabled = true
      this.btnRotate.inputEnabled = true

      if (this.game.levelDone) {
        this.slideRight.visible = false
        this.slideRight.frame = 0

        this.trainClose.visible = true
        this.trainClose.animations.play('closeTrain')
      } else {
        this.slideRight.visible = false
        this.slideLeft.visible = true
        this.slideRight.frame = 0
      }

      this.openedRoofIsRight = !this.openedRoofIsRight
    }, this)

    this.close.onStart.add(() => {
      this.btnDown.inputEnabled = false
      this.btnMoveRoof.inputEnabled = false
      this.btnRotate.inputEnabled = false

      this.Truck.visible = false
      this.slideRight.visible = false
      this.slideLeft.visible = false

      setTimeout(() => {
        this.Crane.groupTetromino.killAll();
      }, 4000)
    })

    this.close.onComplete.add(() => {
      this.levelDone = true;
      this.game.levelDone = false;

      this.trainClose.visible = false;
      this.close.frame = 0;

      this.trainLeft.visible = false;
      this.trainRight.visible = false;
      this.trainMove.visible = true;
      this.trainMove.animations.play('moveTrain');
    })

    this.bg_buttons = this.game.add.image(this.game.width, this.game.height / 2, 'bg_exit_and_pause')
    this.bg_buttons.anchor.set(1, 0.5)
    this.game.add.button(this.bg_buttons.x - this.bg_buttons.width / 2, this.bg_buttons.y - this.bg_buttons.height / 2 + 10, 'btn_exit', this.exit, this).anchor.set(0.5, 0)
    this.game.add.button(this.bg_buttons.x - this.bg_buttons.width / 2, this.bg_buttons.y + this.bg_buttons.height / 2 - 10, 'btn_pause', this.pause, this).anchor.set(0.5, 1)
  }

  update () {
    this.goodsText.text = this.game.totalMoney + ' %';
    this.goodsText.x = this.bg_loading.centerX;

    this.cropRectLoadingscale.width = 239 / 100 * this.game.totalMoney;
    this.loadscale.updateCrop();

    if (this.game.isGameOver) {
      this.btnDown.inputEnabled = false;
      this.btnMoveRoof.inputEnabled = false;
      this.btnRotate.inputEnabled = false;
      this.game.physics.clear();
      this.game.add.image(this.game.width / 2, this.game.height / 2 - 100, this.game.typeGameOver).anchor.set(0.5);
    }

    if (this.game.levelDone) {
      if (!this.openedRoofIsRight) {
        this.openedRoofTrain.body.x = this.Truck.x - this.Truck.width / 2 + 530
        this.slideRight.animations.play('slide_rightTrain')
      } else {
        this.trainClose.visible = true;
        this.trainClose.animations.play('closeTrain');
      }
    }
  }

  craneThrow () {
    if (!this.game.isPlaced) {
      this.Crane.throwTetromino();
      this.game.isPlaced = true;
    }
  }

  craneRotate () {
    if (!this.game.isPlaced) {
      this.Crane.rotateTetromino();
    }
  }

  moveRoof () {
    if (!this.game.isPlaced) {
      if (this.openedRoofIsRight) {
        this.openedRoofTrain.body.x = this.Truck.x - this.Truck.width / 2 + 140;
        this.slideLeft.animations.play('slide_leftTrain');
      } else {
        this.openedRoofTrain.body.x = this.Truck.x + this.Truck.width / 2 - 130;
        this.slideRight.animations.play('slide_rightTrain');
      }
    }
  }

  exit() {
    this.game.state.start('Map');
  }

  pause() {
    this.game.paused = (this.game.paused) ? false : true
  }
}

export default Train
