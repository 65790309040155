import Tetromino from './Tetromino'

class Crane extends Phaser.Sprite {
  constructor (game, gameLevel) {
    super(game, game.width / 2, 223, 'forklift')
    this.anchor.set(0.5, 1)
    this.game = game
    this.gameLevel = gameLevel

    this.direction = 'left'
    this.groupTetromino = this.game.add.group()
    this.stop = true
    this.tetrominoCreated = false
  }

  changeCrane (typeTetromino, angle, coordX) {
    const t = typeTetromino
    const a = angle
    const x = coordX

    if (t === 'O') {
      this.loadTexture('crane_2')
    } else if (t === 'I' && (a === 0 || a === -180)) {
      this.loadTexture('crane_4')
    } else if (t === 'I' && (a === 90 || a === -90)) {
      this.loadTexture('crane_1')
    } else if (t === 'T' && a === 0) {
      this.loadTexture('crane_3')
    } else if (t === 'T' && a !== 0) {
      this.loadTexture('crane_1')
    } else if (t === 'J' && (a === 0 || a === -90)) {
      this.loadTexture('crane_1')
    } else if (t === 'J' && a === 90) {
      this.loadTexture('crane_2')
    } else if (t === 'J' && a === -180) {
      this.loadTexture('crane_3')
    } else if (t === 'L' && (a === 0 || a === 90)) {
      this.loadTexture('crane_1')
    } else if (t === 'L' && a === -90) {
      this.loadTexture('crane_2')
    } else if (t === 'L' && a === -180) {
      this.loadTexture('crane_3')
    } else if ((t === 'Z' || t === 'S') && (a === 0 || a === -180)) {
      this.loadTexture('crane_2')
    } else if ((t === 'Z' || t === 'S') && (a === 90 || a === -90)) {
      this.loadTexture('crane_1')
    }

    // if (t === 'J' && a === 0) {
    //   this.x = x - 30
    // } else if (t === 'J' && a === -90) {
    //   this.x = x + 15
    // } else if (t === 'L' && a === 0) {
    //   this.x = x + 30
    // } else if (t === 'L' && a === 90) {
    //   this.x = x - 15
    // } else if (t === 'T' && a === 90) {
    //   this.x = x + 15
    // } else if (t === 'T' && a === -90) {
    //   this.x = x - 15
    // } else if (t === 'Z' && (a === 0 || a === -180)) {
    //   this.x = x - 15
    // } else if (t === 'Z' && (a === 90 || a === -90)) {
    //   this.x = x + 15
    // } else if (t === 'S' && (a === 0 || a === -180)) {
    //   this.x = x + 15
    // } else if (t === 'S' && (a === 90 || a === -90)) {
    //   this.x = x - 15
    // } else {
    // }
    this.x = x;
  }

  generateTetromino (game, crane, truck) {
    if (!this.tetrominoCreated) {
      this.Tetromino = new Tetromino(game, crane, truck)
      this.game.add.existing(this.Tetromino)
      this.tetrominoCreated = true
    }
  }

  throwTetromino () {
    this.Tetromino.moveDawn()
  }

  rotateTetromino () {
    this.Tetromino.rotate()
  }
}

export default Crane
