class Truck extends Phaser.Sprite {
  constructor (game, gameLevel) {
    super(game, game.width / 2 + 5, game.height / 2 + 100, 'truckTop');
    this.anchor.set(0.5);

    this.game = game;
    this.gameLevel = gameLevel;

    this.game.physics.p2.enable(this);

    this.body.clearShapes();

    this.body.loadPolygon('physicsData', 'truckTop');
    this.body.static = true;
  }

  get boundaries () {
    let boundaries = {
      left: this.x - this.width / 2 + 17,
      right: this.x - this.width / 2 + 580,
      top: this.y - this.height / 2,
    };

    return boundaries;
  }
}

export default Truck;
